@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap');

.faq-section {
  display: flex; /* Enable flexbox layout */
  flex-direction: column; /* Stack content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  min-height: 100vh; /* Make the section take up the full viewport height */
  padding: 3rem 1rem;
  background-color: #f5f5f5;
  text-align: center;
  font-family: 'Jost', sans-serif;
}

.faq-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.faq-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #111;
  margin-bottom: 1rem;
}

.faq-categories {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.faq-category-button {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #000000;
  background-color: #19223400; /* Blue button */
  border: 2px solid #000000;
  transition: background-color 0.3s ease, color 0.3s ease;
  
}

.faq-category-button:hover {
  background-color: #333;
  color: #fff;
}

.faq-category-button.active {
  background-color: #192234;
  color: #fff;
}

.faq-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 100%;
  margin: 2rem auto;
  padding: 0 1rem;
}

.faq-item {
  position: relative;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  width: 100%;
  background-color: #f8f4f4;
  border: 1px solid #ddd;
}

.faq-item:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle glow effect on hover */
}

.faq-item.open {
  background-color: #eaeaea; /* Highlighted background for active FAQ */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow for active FAQ */
}

.faq-question {
  font-size: 1.25rem;
  color: #111;
}

.faq-answer {
  overflow: hidden;
  max-height: 0;
  display: block;
  margin-top: 0;
  padding: 0;
  font-size: 1rem;
  color: #000;
  line-height: 1.5;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.faq-item.open .faq-answer {
  max-height: 300px; /* Adjust as needed */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.faq-navigation-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Adjusted height to fit content */
  margin-top: 3rem; /* Adds spacing from the content above */
  position: relative; /* Relative positioning */
  z-index: 10; /* Ensure it stays above other elements */
}

.faq-navigation-button {
  background-color: transparent; /* Transparent background */
  border: none;
  display: flex; /* Flexbox for layout */
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, color 0.3s ease;
  padding: 1rem; /* Add padding for better visibility */
  cursor: pointer; /* Add cursor styling */
}

.faq-navigation-icon {
  font-size: 2.5rem; /* Large icon */
  color: #000000; /* Black color */
  font-weight: bold;
}

.faq-navigation-subtitle {
  font-size: 1.2rem; /* Subtitle font size */
  color: #000000; /* Black color */
  margin-top: 0.5rem;
}


/* For Mobile View */
@media (max-width: 768px) {
  .faq-categories {
    flex-wrap: wrap; /* Allow buttons to wrap onto the next line if needed */
    justify-content: center; /* Keep buttons centered even if they wrap */
    gap: 0.5rem; /* Reduce spacing between buttons on smaller screens */
    text-align: center;
  }

  .faq-category-button {
    flex: 1 1 auto; /* Allow buttons to resize dynamically */
    max-width: 100px; /* Limit button width for smaller screens */

  }

  .faq-category-button {
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Center the text horizontally */
    align-items: center; /* Center the text vertically */
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color: #000000;
    background-color: #19223400; /* Transparent background */
    border: 2px solid #000000;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center; /* Ensure text aligns properly within the button */
  }
  
  .faq-category-button:hover {
    background-color: #333;
    color: #fff;
  }
  
  .faq-category-button.active {
    background-color: #192234;
    color: #fff;
  }
  
}