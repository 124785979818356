/* Features Page Styles */
.features-page {
  padding: 4rem 2rem;
  background-color: #192234;
  font-family: Arial, sans-serif;
  color: #333;
}

.features-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  margin-bottom: 2rem;
}

.features-table-container {
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: auto; /* Ensure table is scrollable on smaller screens */
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.features-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
}

.features-table th {
  background-color: #192234;
  color: #ffffff;
  text-align: left;
  padding: 1rem;
}

.features-table td {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.features-table td.feature-category {
  font-weight: bold;
  color: #192234;
}

.features-table tbody tr:hover {
  background-color: #f2f2f2; /* Highlight row on hover */
}

@media (max-width: 768px) {
  .features-title {
    font-size: 2rem;
  }

  .features-table th,
  .features-table td {
    padding: 0.5rem;
  }
}
