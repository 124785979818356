.selectionContainer {
    display: grid;
    grid-gap: 0px;
    grid-auto-rows: 25px;
    text-align: left;
    background-color: rgba(0,0,0,0.2);
    height: 80px;
    width: 110%;
    color: white;
    padding-left: 3%;
}

.item {
    align-self: start;
    margin: 0;
}