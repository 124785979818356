.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #192234; 
    height: 100vh;
    padding: 100 6rem; /* Add horizontal padding to move content away from the edges */
    color: #f8f8f8;
    position: relative;
  }

/* Center Content */
.hero-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5%; /* Pushes content down and centers horizontally */
    width: 100%;
    max-width: 80%; /* Restricts width for better centering */
    padding-top: 2%;
  }
  
  .hero-content {
    max-width: 65%;
    display:block "flex";
    flex-direction: "column";
    align-items: "flex-start";
  }
  
  .hero-title {
    font-size: 3rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }
  
  .hero-title span {
    display: block;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    color: #b3b8c4;
    max-width: 80%;
  }
  
  .email-form-hero {
    display: flex;
    align-items: center;
    gap: 0;
    border: 1px solid #b3b8c4;
    border-radius: 0px;
    overflow: hidden;
    max-width: 100%;
  }
  
  .email-input-hero {
    flex: 1;
    padding: 1rem;
    border: none;
    outline: none;
    background-color: transparent;
    color: #FDFFFF;
    font-size: 1rem;
  }
  
  .email-input:focus {
    box-shadow: none; /* Disable individual shadow */
  }
  
  .email-input::placeholder {
    color: #b3b8c4;
  }
  
  /* CTA Button */
  .cta-button-hero {
    padding: 1rem 2.5rem;
    background-color: transparent; /* Transparent background */
    border: none; /* Remove individual border */
    border-left: 2px solid #b3b8c4; /* Add left border to separate button and input */
    color: #b3b8c4;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  }
  
  .cta-button-hero:hover {
    background-color: #09884D;
    
  }
  
  /* Animation Placeholder */
  .hero-animation {
    max-width: 40%;
  }

    .hero-animation img {
    max-width: 100%; /* Makes the image responsive */
    height: auto; /* Maintains aspect ratio */
    display: block; /* Prevents inline spacing issues */
    margin: 0 20%; /* Centers the image */
  }
  .animation-placeholder {
    width: 100%;
    height: 30rem;
    background-color: #f5ede4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #000;
  }
  
  /* Container for the hero navigation button */
  .hero-navigation-button-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
  
  /* Hero navigation button styling */
  .hero-navigation-button {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
  }
  
  .hero-navigation-button:hover {
  color: #ffffff;
  text-decoration: bold;
  
  }
  
  /* Hero navigation arrow icon styling */
  .hero-navigation-icon {
    font-size: 2.5rem; /* Larger size for the arrow */
    color: #ffffff; /* Matches the color scheme */
    font-weight: bold;
  }
  
  /* Subtitle below the arrow */
  .hero-navigation-subtitle {
    font-size: 1.2rem;
    color: #b3b8c4;
    margin-top: 0.5rem;
  }
  
  
/* Media query for smaller screens */
@media (max-width: 768px) {
  .hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #192234; 
    height: 100vh;
    padding: 100 6rem; /* Add horizontal padding to move content away from the edges */
    color: #f8f8f8;
    position: relative;
  }
  
/* Center Content */
.hero-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5%; /* Pushes content down and centers horizontally */
    width: 100%;
    max-width: 85%; /* Restricts width for better centering */
    padding-top: 2%;
  }
  
  .hero-content {
    max-width: 100%;
  }
  
  .hero-title {
    font-size: 1.4rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  
  .hero-title span {
    display: block;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    color: #b3b8c4;
    max-width: 100%;
  }
  
  .email-form {
    display: flex;
    align-items: center;
    max-width: 60%; /* Adjust width */
    overflow: hidden; /* Ensures a smooth, attached appearance */
  }
  
  .email-input {
    flex: 1;
    padding: rem;
    border: none; /* Remove individual borders */
    background-color: transparent;
    font-size: 1rem;
    color: #b3b8c4;
    background-color: rgba(245, 237, 228, 0.068);
    outline: none;
  }
  
  .email-input:focus {
    box-shadow: none; /* Disable individual shadow */
  }
  
  .email-input::placeholder {
    color: #b3b8c4;
  }
  
  /* CTA Button */
  .cta-button-main {
    padding: 1rem 2rem;
    background-color: transparent; /* Transparent background */
    border: none; /* Remove individual border */
    border-left: 1px solid #b3b8c4; /* Add left border to separate button and input */
    color: #b3b8c4;
    font-weight: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  }
  
  .cta-button-hero:hover {
    background-color: #09884D;
  
  }
  
  
  .animation-placeholder {
    display: none;
  }
  
  /* Container for the hero navigation button */
  .hero-navigation-button-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
  
  /* Hero navigation button styling */
  .hero-navigation-button {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
  }
  
  .hero-navigation-button:hover {
  color: #ffffff;
  text-decoration: bold;
  
  }
  
  /* Hero navigation arrow icon styling */
  .hero-navigation-icon {

  }
    .hero-animation {
    display: none;
  }
  /* Subtitle below the arrow */
  .hero-navigation-subtitle {
padding-top: 50%;
  }
  
}
