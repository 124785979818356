.allArticleParentContainer{
    width:100vw;
    height:100vh;
    /* Enable scrolling when content exceeds height! */
    overflow: auto;
}

/* Class for filterbox from profile (since won't be down 3 pages (3vh instead of 205vh) */
.filterBoxFromProfile {
    position: absolute;
    top: 3vh;
    background-color: white;
    z-index: 0;
}
.filterBox {
    position: absolute;
    top: 205vh;
    background-color: white;
    z-index: 0;
}
.showFilterBox {
    text-decoration:underline;
    cursor: pointer;
}

/*-----Styles for articles being displayed-----*/
.articleContainer {
    white-space: nowrap;
    margin-bottom:15px;
    margin-top: 1%;
    margin-left:7%;
    margin-right:7%;
    border: 1px solid black;
    max-width: 95%;
    background-color:#333333 ;
    border-radius: 9px;
}
.articleContainer > p {
    display: inline-block;
    margin-right: 1%;

}

.likeButton {
    vertical-align: sub; /* Align the element to the bottom of the line */
    padding: 5px;
    
}

/*----Styles for sort component-----*/
.sortSelection {
    display: inline-block;
    
}
.sortSelection > div {
    display: inline-block;
    margin: 5px;
    cursor: pointer;
}

.trendingArticleParagraph {
    color:rgb(255, 255, 255);
}
