
.loginPageContainer {
  width: 100%;
  z-index: 1;
  background-color: #192234;
  font-family: "Jost", system-ui;

}

.loginPageFormContainer {
  max-width: 100%; /* Ensure it doesn't exceed the screen width */
  min-width: 25%;
  padding: 2rem; /* Add padding inside the form container */
  background-color: #192234; /* Background for form */
  text-align: center; /* Center-align text */
  height: 90vh; /* Form container height */
}

.loginPageTitle {
  font-family: "Jost", system-ui;
  font-size: 36px;
  font-weight: 500;
  cursor: default;
  word-spacing: 1px;
  color: #ffffff;
  position: relative;
  text-align: center;
}

.loginPageCredForm label {
width: 100%; /* Make sure the label spans the full width */
text-align: left; /* Align the label text to the left */
display: block; /* Ensure the label behaves as a block element */
margin-bottom: 0px; /* Add some space between the label and input */
font-size: 16px; /* Customize the label text size */
color: #ffffff; /* Adjust label text color if needed */
  z-index: 20;
}

.inputStyle {
  width: 100%; /* Span the bar across the full container */
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  font-size: 14px;
  color: #000000;
  background-color: rgb(236, 250, 255); /* Make the background color light blue */
  border: 2px solid transparent;
  transition: border-color 0.3s; /* Add a smooth transition for border color changes */
  text-align: left;
  
}

.inputStyle:focus{
  border-color: #6f00ff; /* Change border color to dark blue on focus */
  width: 100%; /* Span the bar across the whole length of the container */
  height: 40px; /* Customization for the vertical length of the bar */
  padding: 10px;
  border: 2px solid #8979FF; /* Default border color */
  box-sizing: border-box;
  font-size: 14px;
  color: #000000;
  background-color: rgb(236, 250, 255); /* Make the background color light blue */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s; /* Add a smooth transition for border color changes */

}

.loginPageCredForm {
  display: grid;
  align-items: center;
  grid-template-rows: auto 1fr;
}


.loginPageNavlink {
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border: none;
  background-color: #c4c4c4;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.loginPageNavlink:hover {
  background-color: #09884D;
  cursor: pointer;
}
.customDatePicker{
  width:98%;
}
.loginPageLink {
  justify-self: center;
  align-self: center;
  font-family: "Jost", system-ui;
  font-weight: 400;
  cursor: pointer;
  padding: 5px;
  color: #8979FF;
  word-spacing: -1px;
  text-decoration: none;
}

.loginPageLink:hover {
  text-align: center;
  justify-self: center;
  align-self: center;
  font-weight: 400;
  cursor: pointer;
  padding: 5px;
  color: #949494;
  word-spacing: -1px;
}

.loginPageText {
  justify-self: center;
  align-self: center;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  cursor: default;
  word-spacing: -1px;  
  align-self: center; /* align vertically in cell */
  justify-self:center; /* align horizontally in cell */
  
}

.loginContainer {
  padding: 0px;
  margin-bottom: -20%;
}


.googleLogin {
  align-self: center;
  justify-self: center;
}

.enterBirthdayModal {
width:50%;
height:50%;
position: fixed;
top: 50%; /* Center vertically */
left: 50%; /* Center horizontally */
transform: translate(-50%, -50%); /* Center both vertically and horizontally */
z-index: 2; /* Adjust z-index as needed */
background-color: white; /* Modal background color */
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Optional shadow effect */
padding: 5%; /* Optional padding */
}

.enterBirthdayModalContent{
position: relative; /* Use relative positioning to position relative toparent  modal */
top: 30%;
left: 50%;
transform: translate(-50%, -50%);
}
.enterBirthdayModalContent>button {
margin-top:5%;
}

.errorMessage {
padding: 10px;
margin: 10px 0;
text-align: center;
color: rgb(0, 0, 0);
font-weight: bold;
animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
from {
  opacity: 0;
}
to {
  opacity: 1;
}
}

.link {
color: #888888; /* Customize the color */
text-decoration: none;
margin-left: 5px; /* Adds space to the left */
margin-right: 5px; /* Adds space to the right */
}

.link:hover {
text-decoration: underline; /* Add underline on hover */
}


.loginPageDisclaimer {
color: #505050;
text-align: center;

}

.accessContainer {
position: relative; /* Allows the use of 'top' to move it down */
top: 50px; /* Adjust this value to move the section down */
text-align: center;
}

.bioInputStyle {
width: 100%; /* Span the textarea across the full container */
min-height: 100px; /* Minimum height */
padding: 10px;
margin: 10px 0;

box-sizing: border-box;
font-size: 14px;
color: #000000;
background-color: rgb(236, 250, 255); /* Make the background color light blue */
border: 2px solid transparent;
resize: none; /* Disable manual resizing */
transition: border-color 0.3s; /* Smooth transition for border color */
overflow-wrap: break-word; /* Ensure text breaks/wraps inside the textarea */
white-space: normal; /* Ensures proper wrapping of text */
text-align: left; /* Align text to the left */
vertical-align: top; /* Ensure the text starts at the top */
overflow-y: auto; /* Add a scrollbar if the content overflows */
}

/* Start text at the top of the textarea */
.bioInputStyle::placeholder {
vertical-align: top; /* Ensure the placeholder text also starts at the top */
}

/* Change the border color to orange when the textarea is focused */
.bioInputStyle:focus {
border-color: #8979FF; /* Change border color to dark blue on focus */
width: 100%; /* Span the bar across the whole length of the container */
height: 40px; /* Customization for the vertical length of the bar */
padding: 10px;
border: 2px solid #8979FF; /* Default border color */
box-sizing: border-box;
font-size: 14px;
color: #000000;
background-color: rgb(236, 250, 255); /* Make the background color light blue */
outline: none; /* Remove default outline */
transition: border-color 0.3s; /* Add a smooth transition for border color changes */
}


/* 




.login-page-container {
  top: 0px;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  position: absolute;
  align-items: center;
  border-color: #000000;
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(133, 220, 255, 0.55);
}
.login-page-text {
  font-size: 3em;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Open Sans;
}
.login-page-form-container {
  width: 300px;
  height: 500px;
  display: flex;
  z-index: 100;
  position: relative;
  margin-top: 15%;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(255, 0, 0, 0.59);
}
.login-page-cred-form {
  width: 300px;
  height: 500px;
  display: flex;
  z-index: 100;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-page-text01 {
  align-self: center;
}
.c-login-page-navlink {
  color: #ffffff;
  border: none;
  width: 95%;
  height: 36px;
  align-self: center;
  margin-top: 10%;
  text-align: center;
  transition: 0.3s;
  text-decoration: none;
  background-color: #2c6522;
}
.c-login-page-navlink:hover {
  background-color: #27d611;
}
.c-login-page-text05 {
  align-self: center;
  margin-top: 2%;
  margin-bottom: 2%;
}
.c-login-page-navlink1 {
  gap: 10px;
  color: #ffffff;
  height: 20px;
  width: 85%;
  display: flex;
  align-self: center;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #2c6522;
}
.c-login-page-navlink1:hover {
  background-color: #27d611;
}
.c-login-page-icon {
  width: 24px;
}
.c-login-page-container4 {
  display: flex;
  align-self: center;
  margin-top: 5%;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.c-login-page-text11 {
  align-self: center;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.c-login-page-link {
  font-family: Inter;
  font-weight: 700;
}
.c-login-page-text13 {
  font-weight: 600;
  text-decoration: underline;
}
.c-login-page-text14 {
  font-weight: 600;
  text-decoration: underline;
}

.c-google-login-container {

}
 */


 /* Center Content in Login Container */
.loginPageContainer {
height: 100vh; /* Full viewport height */
display: flex; /* Flexbox for centering */
flex-direction: column; /* Stack elements vertically */
justify-content: center; /* Center vertically */
align-items: center; /* Center horizontally */
background-color: #192234; /* Background color */
padding: 1rem; /* Add some padding for spacing */
font-family: "Jost", system-ui; /* Ensure consistent font */
}

/* Login Form Container */
.loginPageFormContainer {
max-width: 100%; /* Ensure it doesn't exceed the screen width */
padding: 2rem; /* Add padding inside the form container */
background-color: #192234; /* Background for form */
border-radius: 8px; /* Optional rounded corners */
text-align: center; /* Center-align text */
height: 90vh;
padding-top: 20%;
}

/* Input Fields */
.inputStyle {
width: 100%; /* Full width within the container */
padding: 1rem; /* Add padding for touch-friendly input */
margin: 0.5rem 0; /* Add spacing between inputs */
box-sizing: border-box; /* Include padding in width calculation */
font-size: 1rem; /* Ensure readable font size */
color: #000000; /* Text color */
background-color: rgb(236, 250, 255); /* Light blue background */
border: 2px solid transparent; /* Border style */
border-radius: 4px; /* Rounded edges for better aesthetics */
transition: border-color 0.3s ease; /* Smooth transition for focus effect */
}

/* Input Fields on Focus */
.inputStyle:focus {
border-color: #8979FF; /* Highlight border on focus */
outline: none; /* Remove default outline */
}

/* Login Title */
.loginPageTitle {
font-size: 2rem; /* Adjust font size for mobile */
color: #ffffff; /* White text */
margin-bottom: 1rem; /* Add space below title */
text-align: center; /* Center-align the title */
}

/* Login Button */
.loginPageNavlink {
width: 100%; /* Full width of the container */
padding: 0.8rem; /* Add padding for better touch targets */
font-size: 1rem; /* Font size for button text */
background-color: #c4c4c4; /* Default background color */
color: #ffffff; /* Button text color */
border: none; /* Remove border */
border-radius: 4px; /* Optional rounded corners */
cursor: pointer; /* Change cursor on hover */
transition: background-color 0.3s ease; /* Smooth hover effect */
}

/* Hover Effect for Button */
.loginPageNavlink:hover {
background-color: #09884D; /* Change color on hover */
}

/* Center Text Below the Form */
.loginPageText {
font-size: 1rem; /* Adjust font size */
color: #b3b8c4; /* Subtle text color */
margin-top: 1rem; /* Add spacing above the text */
text-align: center; /* Center-align text */
}
