/* Style for the container that holds the information icon */
.information-helper {
    position: relative; /* Position relative for proper positioning */
    display: inline-block; /* Display as inline block to fit content */
}

/* Style for the message (tooltip) */
.message {
    position: absolute; /* Position absolutely within the container */
    background-color: white; /* Background color of the tooltip */
    color: black; /* Text color of the tooltip */
    padding: 10px; /* Padding for the tooltip content */
    border-radius: 4px; /* Rounded corners for the tooltip */
    z-index: 1; /* Ensure the tooltip appears above other elements */
    opacity: 0; /* Initially transparent */
    white-space: normal; /* Allow text to wrap within the tooltip */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a box shadow for a raised effect */
    width: 200px;
    visibility: hidden; /* Initially hidden */
    transition: opacity 0.5s ease-in-out; /* Define opacity transition */
}

/* Style for the arrow */
.arrow {
    position: absolute; /* Position absolutely within the container */
    width: 0;
    height: 0;
    border-left: 10px solid transparent; /* Create the arrow shape */
    border-right: 10px solid transparent;
    border-top: 10px solid white; /* Arrow color */
    bottom: -10px; /* Position the arrow outside the tooltip */
    left: 50%; /* Position the arrow horizontally centered */
    transform: translateX(-50%); /* Center the arrow horizontally */
}

/* Style for the tooltip when hovering over the information icon */
.information-helper:hover .message {
    opacity: 1; /* Make it visible */
    visibility: visible; /* Make it visible */
    display: block; /* Display the tooltip when hovering over the icon */
    bottom: calc(100% + 10px); /* Position the tooltip below the icon */
    left: 50%; /* Position the tooltip horizontally centered */
    transform: translateX(-50%); /* Center the tooltip horizontally */
    transition-delay: 0.2s; /* Delay the tooltip appearance */
}