/*----- Keyword styling -----*/
.keywordParagraphContainer{
    display:flex;
    flex-wrap:wrap;
}
.keywordHeader{
    text-align:center;
}
.keywordParagraphs{
    /* Set right and bottom margins to 10 and rest to 0 */
    margin: 0 10px 10px 0; 
}


/*----- Related category styling -----*/
.relatedCategoryContainer{
    display:flex;
    flex-wrap:wrap;
}
.relatedCategoryParagraph{
    /* Set margin to 0 all around for paragraphs */
    margin: 0; 
}
.relatedCategoryHeader{
    margin:0;
}

/*----- Active filter styling -----*/
.activeFilterHeader {
    text-align:center;
    /* Set top and bottom margins to 5px */
    margin: 1% 0 1% 0;
}

.activeFilterParagraph {
    /* Set margin left to 1% */
    margin:0 0 0 1%;
}

/*----- Trending Articles -----*/

.trendingArticlesExpanded {
    position: absolute;
    width:100%;
    height:100%;
    z-index:10;
}

.trendingArticleTitle {
    width:100%;
}

/* Set display to table so that it takes up overflow width as well (what's scrolled to)! */
.trendingArticleList {
    white-space: nowrap;
    width:100%;
    display:table;
}

.trendingArticleContainer {
    width:100%;
    border: 1px solid black;
    display: table;
}
.trendingArticleGrayBackground {
    background-color: rgb(194, 194, 194);
}
.trendingArticleWhiteBackground {
    background-color: rgb(243, 242, 242);
}
.trendingArticleParagraph {
    margin:0;
}
.trendingArticlesExpandButton {
    position: absolute;
    top:1%;
    right:1%;
}
.trendingArticlesShowAllButton {
    position: sticky;
    bottom: 0;
    background-color: #007bff; /* Example background color */
    color: #fff; /* Example text color */
    border: none;
    cursor: pointer;
}
.positiveSentScore {
    color: green;
}
.negativeSentScore {
    color: red;
}
.neutralSentScore{
    color: black;
}

