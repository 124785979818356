/* Algorithm Section Styling */
.algorithm-section {
    background-color: #E8E4E4; /* Light background */
    padding: 4rem 2rem;
    text-align: center;
    height: 100vh;
  }
  
  .algo-header {
    margin-bottom: 2rem;
  }
  
  .algo-title {
    font-size: 2rem;
    font-weight: bold;
    color: #192234; /* Dark color for title */
  }
  
  /* Grid Container */
  .custom-grid {
    display: grid;
    grid-template-areas: 
      "box1 box3 box2"
      "box1 box3 box2"
      "box4 box3 box5"
      "box4 box3 box5";
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; /* Spacing between grid items */
    width: 100vh;
    max-width:100vh;
    margin: 0 auto; /* Center the grid */
    height: 60%; /* Set height of the grid */
    max-height: 40%;
    
  }
  
  /* Grid Items */
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensures images do not overflow the container */
    border: 1px solid #aaa; /* Optional border for clarity */
  }
  
  .grid-item img {
    width: 100%;
    height: 100%;
    object-fit: fit; /* Ensures the image fills the container while maintaining aspect ratio */
  }
  
  /* Specific Sizes and Positions */
  .box-1 {
    grid-area: box1; /* Top-left corner */
  }
  
  .box-2 {
    grid-area: box2; /* Top-right corner */
  }
  
  .box-3 {
    grid-area: box3; /* Large center image */
  }
  
  .box-4 {
    grid-area: box4; /* Bottom-left corner */
  }
  
  .box-5 {
    grid-area: box5; /* Bottom-right corner */
  }
  

  
  /* Card Styling */
  .algo-card {
    background-color: #ffffff; /* White background */
    border: 1px solid #e0e0e0; /* Subtle border */
    padding: 0.5rem 1rem; /* Compact padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Minimal shadow */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem; /* Adjusted for proportional height */
    width: 100%; /* Full width */
  }
  
  .algo-card img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Footer Styling */
  .algo-footer {
    margin-top: 2rem;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center;    /* Horizontally center children */
    text-align: center;     /* Center the text within the paragraph */
  }
  
  .algo-footer p {
    font-size: 1.4rem;
    color: #666;
    margin-bottom: 1rem;
    max-width: 60%;
    padding-bottom: 2%;
  }
  
  .algo-cta-button {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color: #192234;
    background-color: #19223400; /* Blue button */
    border: 2px solid #192234;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .algo-cta-button:hover {
    background-color: #192234;
    color: #ffffff;
    border: 2px solid #1e3a8a;
  }
  
.analysis-navigation-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; /* Adjusted height to fit content */
    margin-top: 3rem; /* Adds spacing from the content above */
    position: relative; /* Relative positioning */
    z-index: 10; /* Ensure it stays above other elements */
}

.analysis-navigation-button {
    background-color: transparent; /* Transparent background */
    border: none;
    display: flex; /* Flexbox for layout */
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, color 0.3s ease;
    padding: 1rem; /* Add padding for better visibility */
    cursor: pointer; /* Add cursor styling */
}

.analysis-navigation-icon {
    font-size: 2.5rem; /* Large icon */
    color: #000000; /* Black color */
    font-weight: bold;
}

.analysis-navigation-subtitle {
    font-size: 1.2rem; /* Subtitle font size */
    color: #000000; /* Black color */
    margin-top: 0.5rem;
}
/* Mobile Layout Adjustments */
@media (max-width: 768px) {
  .algorithm-section {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    text-align: center;
    height: 150vh; /* Full screen height */
    padding: 2rem; /* Add padding around content */
    box-sizing: border-box; /* Include padding in height/width calculations */
  }

  .algo-header {
    margin-top: 0%;
  }
  
  .algo-title {
    font-size: 2rem;
    font-weight: bold;
    color: #192234; /* Dark color for title */
  }

  .custom-grid {
    display: flex; /* Ensure the grid container is visible */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    height: 100%; /* Full height */
    width: 100%; /* Full width */
  }

  /* Hide other grid items */
  .box-1,
  .box-2,
  .box-4,
  .box-5 {
    display: none; /* Hide these items */
  }

  .box-3 {
    display: flex; /* Ensure box-3 is visible and flexible */
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
    max-width: 100%; /* Limit width to prevent overflow */
    height: auto; /* Allow dynamic height based on content */
    margin: 0 auto; /* Center horizontally */
    background-color: #ffffff; /* Background for clarity */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
    padding: 1rem; /* Add padding inside */
    border-radius: 8px; /* Rounded corners for a clean look */
  }

  .box-3 img {
    display: block; /* Ensure the image is visible */
    max-width: 100%; /* Prevent the image from exceeding the box size */
    height: auto; /* Maintain the aspect ratio */
    object-fit: contain; /* Ensure the image fits inside the container */
  }

  .algo-footer {
    margin-top: 2rem;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Horizontally center children */
    text-align: center; /* Center the text within the paragraph */
  }
  
  .algo-footer p {
    font-size: 1.4rem;
    color: #666;
    margin-bottom: 1rem;
    max-width: 100%;
    padding-bottom: 2%;
  }
}
