/* General layout */
body, html {
  margin: 0;
  padding: 0;
  font-family: "Jost", system-ui;
}

.contact-page {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Jost", system-ui;
}



/* Header styles */
.contact-header {
  text-align: center;
  padding: 20px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.contact-header h1 {
  margin: 0;
  font-size: 2.5rem;
}

/* Form section styles */
.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.contact-form {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 45vh;
  padding: 60px;
  background-color: #313F58;
  color: #fdfdfd;
}

.contact-form label {
  width: 100%;
  text-align: left;
  display: block;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 8px;
}

.contact-form input,
.contact-form select,
.contact-form textarea,
.submit-btn {
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: "Jost", system-ui;
}

/* Input, Select, and Textarea Styles */
.contact-form input,
.contact-form select,
.contact-form textarea {
  border: 1px solid #ffffff; /* Default border color */
}

.contact-form select {
  appearance: none; /* Removes default arrow to allow for custom styling */
  background-color: #243047;
  color: #fdfdfd;
}

.contact-form input:focus,
.contact-form select:focus,
.contact-form textarea:focus {
  border-color: #ffffff;
  outline: none;
  box-shadow: 0 0 0 2px #ffffff; /* Adds a shadow to simulate a thicker border */
}

.contact-form input:hover,
.contact-form select:hover,
.contact-form textarea:hover {
  border-color: #2062c5;
  outline: none;
  box-shadow: 0 0 0 2px #2e00d4; /* Adds a shadow to simulate a thicker border */
}

.contact-form textarea {
  resize: vertical;
  height: 100px;
}
.contactemailmessage {
  color: white;
}
/* Contact page title */
.contactPageTitle {
  font-size: 36px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 25px;
}

/* Submit button styling */
.submit-btn {
  background-color: #243047;
  color: #ffffff;
  border: none;
  text-align: center;
}

.submit-btn:hover {
  background-color: #29883e;
}
