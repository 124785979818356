.hide-text .leaflet-tile-pane > img {
    filter: grayscale(100%) brightness(100%);
}

.container-map{
    filter: brightness(100%);
}
/* Line up the box that shows user's selection */
.selectionBox {
    position: absolute;
    top:10%;
    left:5%;
    z-index: 10;
}

.filterBox {
    position: absolute;
    top:20%;
    right:0%;
    z-index: 10;  
}

.mapContainer {
    position: relative;
}

.parentButtons {
    position: absolute;
    z-index: 10;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
  
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    

    
    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
}

.modalClass {
    position: absolute;
    z-index: 15;
}

.leaflet-control-attribution {
    right:15%;
}

/*Positioning of the zoom icon of the map*/
/* Making it so zoom icon is slightly to the right by changing the react-leaflet classes for the zoom element */
.leaflet-top .leaflet-control-zoom {
    left:25%;
  }