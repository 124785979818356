@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap');

/* General Reset */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Jost', sans-serif;
  background-color: #192234;
  overflow:visible !important;
  scroll-behavior: smooth !important;
  }

/* Tech Section Styles */

.tech-section-container {
  position: absolute;
  bottom: 15vh; /* Adjust this value to move the section up or down as needed */
  left: 0vh;
  transform: translate(-50%, 50%);
  text-align: center;
  cursor: pointer;
  z-index: 2;
  animation: line-down 1.5s ease-out forwards;
  width: 100%; /* Ensures the container spans the entire width of the viewport */
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  padding: 100 6rem; /* Add horizontal padding to move content away from the edges */

}

.tech-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
  padding-top: 0;
  background-color: f8f8f8;
}

.tech-intro {
  font-size: 1.5rem;
  margin-bottom: 1rem; /* Space between the text and the button */
  color: white; /* Ensure the text is visible */
}

.circle-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px; /* Space between the text and the button */
}

.circle-button:hover {
  background-color: #333;
}

.arrow-down {
  font-size: 1.5rem;
  line-height: 1;
}

/* Section Styling */
.data-insights-section {
  text-align: center;
  padding: 2rem 1rem;
  background-color: #ffffff; /* White background */
  color: #192234; /* Dark text */
  height: 100vh;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #192234; /* Darker color for title */
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 15vh;

}

/* Stat Box */
.stat-box {
  background-color: #192234; /* Blue background */
  color: #ffffff; /* White text */
  padding: 1rem 2rem; /* Adjust padding */
  display: flex; /* Use flexbox for horizontal alignment */
  align-items: center; /* Vertically align items */
  justify-content: space-between;
  text-align: left; /* Align text and number horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for effect */
  flex-direction: row;
  
}

.stat-box.hover {
  background-color: #192234; /* Blue background */
  color: #ffffff; /* White text */
  padding: 1rem 2rem; /* Adjust padding */
  display: flex; /* Use flexbox for horizontal alignment */
  align-items: center; /* Vertically align items */
  justify-content: space-between;
  text-align: left; /* Align text and number horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for effect */
  flex-direction: row;
  
}

/* Stat Number */
.stat-number {
  font-size: 2rem;
  font-weight: bold;
  margin-right: 5rem; /* Add spacing between number and label */
  text-align: left;
}

/* Stat Label */
.stat-label {
  font-size: 1.25rem;
  font-weight: normal;
  color: #cbd5e1; /* Slightly lighter text for the label */
  flex: 1; /* Ensures label takes up the remaining space */
  text-align: center;
  padding-right: 10px;
}

/* Stats Grid */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 3rem; /* Add spacing between grid items */
  margin: 0 auto; /* Center the grid horizontally */
  max-width: 80%; /* Reduce the width for better centering */
}

/* Section Description */
.section-description {
  font-size: 1.4rem;
  color: #666; /* Subtle text */
  text-align: center;
  margin: 2rem auto;
  max-width: 59%; /* Restrict maximum width for readability */
  padding-top: 2.5%;
  padding-bottom: 2%;
  
}



/* Responsive Design */
@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr; /* Single column layout for very small screens */
  }
}

.stat-box:nth-child(8) {
  animation-delay: 0.2s;
}

.stat-box:nth-child(7) {
  animation-delay: 0.4s;
}

.stat-box:nth-child(6) {
  animation-delay: 0.6s;
}

.stat-box:nth-child(5) {
  animation-delay: 0.8s;
}

.stat-box:nth-child(4) {
  animation-delay: 1.0s;
}

.stat-box:nth-child(3) {
  animation-delay: 1.2s;
}

.stat-box:nth-child(2) {
  animation-delay: 1.4s;
}

.stat-box:nth-child(1) {
  animation-delay: 1.6s;
}


.hero-navigation-button-container-2 {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100vh; /* Ensures the container spans the full viewport height */
}

/* Hero navigation button styling */
.hero-navigation-button-2 {
  background-color: #ffffff00;
  border: none;

  display:inline;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
  padding-top: 5%;
}

.hero-navigation-button-2:hover {
color:#ffffff00;
text-decoration: bold;
}

/* Hero navigation arrow icon styling */
.hero-navigation-icon-2 {
  font-size: 2.5rem; /* Larger size for the arrow */
  color: #000000; /* Matches the color scheme */
  font-weight: bold;
}

/* Subtitle below the arrow */
.hero-navigation-subtitle-2 {
  font-size: 1.2rem;
  color: #000000;
  margin-top: 0.5rem;
}

  /* Adding lines to the top two FAQs */

  @media (min-width: 768px) {
    .faq-grid {
      align-items: flex-start; /* Ensure items align to the top */
    }
    .faq-item {
      align-self: absolute; /* This makes sure each FAQ stays independent of others */
      width: 100vh ; /* Ensure proper width and spacing */
      text-align: center;
      max-width:100vh;
    }
  }
  @media (max-width: 768px) {
    .faq-item {
      width: 100%; /* Make FAQs full width on smaller screens */
    }
  }
  .FAQCTA-text {
    font-size: 2.6rem;
    padding-top: 10vh;
  }

.cta-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 60%;
  margin-top: 2rem;
  font-size: 0.9rem;
  color: #b3b8c4;
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-link {
  text-decoration: none;
  color: #b3b8c4;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #FDFFFF;
}
section {
  background-color: #192234; /* Blue background */
  padding: 2rem 0; /* Add some padding for spacing */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  color: #ffffff; /* White text */
  
}

.footer-logo .footer-text-small {
  font-size: 0.9rem;
  color: #ffffff; /* White text */
}

.footer-links {
  display: flex;
  gap: 2rem; /* Add spacing between links */
}

.footer-links a {
  color: #ffffff; /* White text */
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease; /* Smooth hover effect */
}

.footer-links a:hover {
  color: #ffffff; /* Highlighted color on hover */
  text-shadow: 0 0 1px #ffffff, 0 0 1px #a7a7a7;
}
