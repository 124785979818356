/* CTA Section */
.cta-section {
    background-color: #192234; /* Dark background */
    color: #FDFFFF;
    text-align: center;
    padding: 4rem 2rem; /* Padding for spacing */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .cta-content {
    max-width: 60%;
    margin-bottom: 2rem;
  }
  
  .cta-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 1.4;
  }
  
  .cta-subtitle {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #b3b8c4;
    margin-bottom: 2rem;
  }
  
  .email-form {
    display: flex;
    align-items: center;
    gap: 0;
    border: 1px solid #b3b8c4;
    border-radius: 0px;
    overflow: hidden;
    max-width: 100%;
  }
  
  .email-input {
    flex: 1;
    padding: 1rem;
    border: none;
    outline: none;
    background-color: transparent;
    color: #FDFFFF;
    font-size: 1rem;
  }
  
  .email-input::placeholder {
    color: #b3b8c4;
  }
  
  .cta-button-main {
    background-color: transparent;
    color: #FDFFFF;
    border-left: 2px solid #b3b8c4;
    padding: 1rem 2.5rem;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .cta-button-main:hover {
    background-color: #09884D;
  }

  @media (max-width: 768px) {
    .cta-section {
      padding: 2rem 1rem; /* Reduce padding for smaller screens */
    }
  
    .cta-content {
      max-width: 90%; /* Allow content to take up more width */
      text-align: center; /* Ensure text is centered */
      margin-bottom: 1.5rem;
    }
  
    .cta-title {
      font-size: 2rem; /* Reduce font size for better fit */
      margin-bottom: 0.75rem; /* Adjust spacing */
    }
  
    .cta-subtitle {
      font-size: 1rem; /* Smaller subtitle font */
      line-height: 1.6; /* Adjust line height */
      margin-bottom: 1.5rem;
    }
  
    .email-form {
      flex-direction: column; /* Stack input and button vertically */
      align-items: stretch; /* Ensure full width for both elements */
      border: none; /* Simplify border for mobile */
      width: 100%; /* Full width on smaller screens */
      max-width: 400px; /* Limit maximum width */
      margin: 0 auto; /* Center the form */
    }
  
    .email-input {
      padding: 0.75rem; /* Adjust padding for smaller screens */
      font-size: 0.9rem; /* Adjust font size */
      margin-bottom: 1rem; /* Add spacing below input */
      border: 1px solid #b3b8c4; /* Add border for clarity */
      border-radius: 4px; /* Round input corners */
      text-align: center;
    }
  
    .cta-button-main {
      padding: 0.75rem 1.5rem; /* Adjust padding for smaller buttons */
      font-size: 1rem; /* Maintain readability */
      width: 100%; /* Make the button full width */
      text-align: center; /* Center the text */
      border-left: none; /* Remove left border for cleaner look */
      border: 1px solid #b3b8c4; /* Match input border */
      border-radius: 4px; /* Round button corners */
      transition: background-color 0.3s ease, color 0.3s ease;
    }
  
    .cta-button-main:hover {
      background-color: #09884D; /* Retain hover effect */
    }
  }
  
  