.navbar {
    display: flex;
    justify-content: center; /* Center the navbar horizontally */
    /* Start navbar in top left */
    top: 1%;
    left:1%;
  }
  
  .navbarContainer {
    display: flex; /* Use flexbox to align items horizontally */
    align-items: center; /* Align items vertically in the center */
  }
  
  .toggleButton {
    /* Make it so button doesn't wrap lines! */
    background-color: transparent;
    border: none;
    white-space: nowrap; 
    cursor: pointer;
  }
  
  .navbarList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; /* Use flexbox to align items horizontally */
    align-items: center; /* Align items vertically in the center */
    opacity: 0; /* Initially hide the navbar */
    visibility: hidden;
    transition: all 0.5s ease; /* Add a transition for smooth animation */
  }

  .navbarList a {
    color: black;
    text-decoration: none;
  }
  
  .isVisible {
    opacity: 1; /* Show the navbar */
    visibility: visible;
  }
  
  .navbarList li {
    margin-right: 10px; /* Add spacing between list items */
  }