
/* Header Styles */
.header {
    position: static;
    top: 0;
    left: auto; /* Ensures it starts from the left edge */
    right: auto;
    width: 100%;
    display: flex;
    justify-content: space-between; /* Ensures space between logo and navigation */
    align-items: center; /* Vertically aligns the content */
    padding: 2rem; /* Adjust padding as needed */
    background-color: #192234; /* Ensure header has a visible background */
    z-index: 1000;
    max-width: 80%; /* Limits content width */
    margin: 0 auto; /* Centers the header */
  }
  
  .logo {
    color: #FDFFFF;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
  }

  .navigation {
    display: flex;
    gap: 2rem;
    z-index: 1000;
  }
  
.nav-link.login-link {
    display: inline-block;
    padding: 0.5rem 2rem; /* Adjust padding for proper button size */
    border: 1px solid #ffffff; /* Border for button */
    background-color: transparent; /* Transparent background */
    color: #ffffff; /* Text color */
    font-size: 1rem; /* Match font size with other links */
    text-align: center;
    text-decoration: none; /* Remove underline */
    vertical-align: middle; /* Align with baseline of other items */
    line-height: 1; /* Adjust line height to match text alignment */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth hover transition */
  }
  
  .nav-link.login-link:hover {
    background-color: #29883e; /* Background on hover */
    color: #e7e7e7; /* Change text color on hover */
  }
  
  .navigation {
    display: flex;
    align-items: center; /* Vertically centers all items */
    gap: 2rem; /* Spacing between links */
  }
  
  .nav-link {
    font-size: 1rem; /* Match font size */
    line-height: 1; /* Match line height */
    color: #b3b8c4; /* Ensure same color */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: #ffffff; /* Highlight on hover */
  }

/* Media Query for Screens Smaller than 792px */
@media (max-width: 792px) {
  .header {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align the elements */
    
    
  }

  .logo {
    color: #FDFFFF;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    padding-bottom: 4%;
  }

  .nav-links {
    flex-direction: column; /* Stack the nav links vertically */
    align-items: center; /* Center align the nav links */
    margin-top: 2rem !important; /* Add spacing below the logo */
    padding: 0 1rem; /* Reduce padding for smaller screens */
    
  }
}
