/* Section Styling */
.data-insights-section {
    text-align: center;
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    color: #192234;
    height: 100vh;
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 5rem;
    margin-top: 15vh;
  }
  
  /* Stats Grid */
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    gap: 3rem;
    margin: 0 auto;
    max-width: 80%; /* Center grid and limit width */
  }
  
  /* Section Description */
  .section-description {
    font-size: 1.4rem;
    margin: 2rem auto;
    max-width: 60%;
    margin-bottom: 1%;
  }
  
  /* CTA Button */
  .cta-button-data {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color: #192234;
    background-color: #19223400; /* Blue button */
    border: 2px solid #192234;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .cta-button-data:hover {
    background-color: #192234;
    color: #ffffff;
  }
  
  
/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
/* Section Styling */
.data-insights-section {
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  color: #192234;
  height: 190vh;
}

  .stats-grid {
    grid-template-columns: 1fr; /* Single column for stacking vertically */
    gap: 1.5rem; /* Reduce gap for mobile screens */
    max-width: 90%; /* Restrict the width to avoid overflowing */
    margin: 0 auto; /* Ensures the grid is horizontally centered */
  }

  .section-title {
    font-size: 2rem; /* Reduce font size for mobile */
    margin-bottom: 3rem;
    margin-top: 1vh;

  }

  .stat-item {
    text-align: center; /* Center-align content inside the stat box */
    align-items: center;
    font-size: 1rem; /* Adjust font size for mobile readability */
  }

  .stat-number {
    font-size: 1.5rem; /* Reduce font size for numbers */
  }

  .stat-label {
    font-size: 0.9rem; /* Adjust label size */
    text-align: right;
  }

    /* Section Description */
    .section-description {
      font-size: 1.4rem;
      margin: 2rem auto;
      max-width: 95%;
      margin-bottom: 10%;
      margin-top: 15%;
    }
    
    /* CTA Button */
    .cta-button-data {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color: #192234;
    background-color: #19223400; /* Blue button */
    border: 2px solid #192234;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    }
    
    .cta-button-data:hover {
      background-color: #192234;
      color: #ffffff;
    }

}
