/* Presentation Section */
.presentation-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  width: 100%;
  padding: 15rem 2rem;
  height: 100vh;
}

/* Main Container */
.presentation-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5rem;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 2rem;
}

/* Left Section */
.presentation-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}
.presentation-title {
  font-size: 2rem;
  font-weight: bold;
  color: #192234; /* Dark color for title */
}

.presentation-description {
  font-size: 1.4rem;
  color: #666;
}


.navigation-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.navigation-button {
  padding: 0.8rem 1.5rem;
  background-color: transparent;
  border: 2px solid #192234;
  font-size: 1rem;
  color: #192234;
  text-align: left;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.navigation-button:hover,
.navigation-button.active {
  background-color: #192234;
  color: #ffffff;
}

/* Right Section */
.presentation-right {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-container {
  width: 100%;
  max-width: 100%;
  margin-top: 20%;
  
}

.dashboard-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.image-tracker {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tracker-block {
  width: 1.5rem;
  height: 0.5rem;
  background-color: #d3d3d3;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.tracker-block.active {
  background-color: #192234;
}

/* Bottom Section */
.presentation-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.presentation-cta {
  margin-bottom: 1rem;
}

.cta-button-visual {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #192234;
  background-color: #19223400; /* Blue button */
  border: 2px solid #192234;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button-visual:hover {
  background-color: #192234;
  color: #ffffff;
  border: 2px solid #1e3a8a;
}

.navigation-button-container-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Adjusted height to fit content */
  margin-top: 3rem; /* Adds spacing from the content above */
  position: relative; /* Relative positioning */
  z-index: 10; /* Ensure it stays above other elements */
}

.navigation-button-faq{
  background-color: transparent; /* Transparent background */
  border: none;
  display: flex; /* Flexbox for layout */
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, color 0.3s ease;
  padding: 1rem; /* Add padding for better visibility */
  cursor: pointer; /* Add cursor styling */
}

.navigation-icon {
  font-size: 2.5rem; /* Large icon */
  color: #000000; /* Black color */
  font-weight: bold;
}

.FAQ-navigation-subtitle {
  font-size: 1.2rem; /* Subtitle font size */
  color: #000000; /* Black color */
  margin-top: 0.5rem;
}

/* Mobile View: Stack Containers for Screens Smaller Than 768px */
@media (max-width: 768px) {

  .presentation-section {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the entire section */
    align-items: center; /* Horizontally center the entire section */
    background-color: #f8f8f8;
    width: 100%;
    height: 110vh;
    padding: 0; /* Remove any padding causing misalignment */
    margin: 0 auto; /* Center the section itself */
  }

  /* Main Container */
  .presentation-container {
    display: flex;
    flex-direction: column; /* Stack all elements vertically */
    justify-content: center; /* Center vertically within the viewport */
    align-items: center; /* Center horizontally */
    width: 100%;
    max-width: 600px; /* Ensure consistent width */
    height: auto; /* Allow the container height to adjust dynamically */
    gap: 2rem; /* Add spacing between elements */
    padding: 0; /* Remove any padding causing offset */
    margin: 0 auto; /* Center horizontally */
  }

  .presentation-left,
  .presentation-right {
    width: 100%; /* Ensure full width */
    text-align: center; /* Center-align text */
    margin: 0; /* Remove any unintended margin */
  }

  .presentation-title {
    font-size: 1.8rem; /* Adjust size for smaller screens */
    margin: 0 auto 1rem auto; /* Center-align and add spacing below */
    text-align: center; /* Center-align title */
  }

  .presentation-text {
    width: 100%; /* Ensure it spans the full width */
    text-align: center; /* Center-align content */
    margin: 0 auto; /* Center the container horizontally */
    padding: 0; /* Remove any padding */
  }

  .presentation-description {
    font-size: 1.2rem; /* Adjust text size for smaller screens */
    margin: 0 auto; /* Center-align description */
    max-width: 90%; /* Limit width for readability */
    text-align: center;
    padding-bottom: 5%;
    margin-bottom: 2%;
  }

  .navigation-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.25rem; /* Add spacing between buttons */
    width: 100%;
    max-width: 90%; /* Limit button container width */
    margin: 0 auto; /* Center the button container */
  }

  .navigation-button {
    text-align: center;
  }

  .dashboard-container {
    margin: 0 auto; /* Center the dashboard container */
    max-width: 90%; /* Ensure it scales to fit */
    width: 100%;
    text-align: center;
  }

  .dashboard-image {
    margin: 0 auto; /* Center the image */
    width: 100%; /* Full width */
    max-width: 600px; /* Limit the maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .presentation-bottom {
    margin-top: 2rem; /* Add spacing between stacked sections */
    width: 100%; /* Full width */
    text-align: center; /* Center-align bottom buttons */
  }

  .presentation-cta {
    margin-bottom: 1rem; /* Add spacing for visual separation */
    text-align: center; /* Center-align CTA button */
  }

  .cta-button-visual {
    margin: 0 auto; /* Center the button */
    display: inline-block; /* Ensure proper alignment */
  }

  .navigation-button-container-visual {
    margin: 0 auto; /* Center-align the navigation container */
  }
}
