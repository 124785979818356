/* Footer Section */
.footer-section {
    background-color: #192234; /* Blue background */
    padding: 2rem 0; /* Add some padding for spacing */
  }
  
  /* Footer Content */
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    color: #ffffff; /* White text */
  }
  
  /* Footer Logo */
  .footer-logo .footer-text-small {
    font-size: 0.9rem;
    color: #ffffff; /* White text */
  }
  
  /* Footer Links */
  .footer-links {
    display: flex;
    gap: 2rem; /* Add spacing between links */
  }
  
  .footer-link {
    color: #b3b8c4; /* Slightly lighter text for links */
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease; /* Smooth hover effect */
  }
  
  .footer-link:hover {
    color: #ffffff; /* Highlighted color on hover */
    text-shadow: 0 0 1px #ffffff, 0 0 1px #a7a7a7;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column; /* Stack the content vertically */
      align-items: center; /* Center-align the items */
      text-align: center; /* Ensure text is centered */
      gap: 1.5rem; /* Add spacing between the links and the logo */
    }
  
    .footer-links {
      flex-direction: column; /* Stack links vertically */
      gap: 1rem; /* Add spacing between individual links */
    }
  
    .footer-link {
      font-size: 1rem; /* Maintain readability on smaller screens */
    }
  
    .footer-logo {
      margin-top: 1rem; /* Add spacing between links and copyright/logo */
    }
  
    .footer-text-small {
      font-size: 0.8rem; /* Slightly reduce font size for copyright text */
      color: #b3b8c4; /* Subtle color for the copyright text */
      text-align: center; /* Center-align the text */
    }
  }
  