.App {
  text-align:center;
}

/* Moving outside of map container! */
/* .parentAnalytics {
  position:absolute;
  z-index: 10;
  bottom:5%;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 1000px) {
      grid-template-columns: 1fr;
  }
} */

.parentAnalytics { /* The analyze button */
  grid-column-start: 9;
  grid-column-end: 10;
  grid-row-start: 10;
  height: auto;
  width: 80%;
  align-self:center; /* Center vertically */
  justify-self:center; /* Center horizontally */
}

.mapContainer { /* The map itself */
  position: relative;
  grid-column-start: 3;
  grid-column-end: 11;
  grid-row-start: 2;
  grid-row-end: 9;
  /*border-radius: 0px;*/
  /* width: 100%;
  height: 50vh; */
}


.currentGeoSelection { /* The information bar */
  /* position: relative; */
  border: 1px solid black;
  border-radius: 0px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 9;
  /* width: 100%;
  height: 50vh; */
  text-align: left;
  padding:2%;
}

.geoSelectionButton {
  height:50%;
  grid-column-start: 8;
  grid-column-end: 10;
  grid-row-start: 8;
  border: 1px solid black;
  border-radius: 5px;
  /* Center vertically and horizontaly */
  display:flex;
  align-items:center;
  justify-content: center;
  align-self:end;
}

.geoSelectionButton:hover {
  background-color: lightgray;
  cursor: pointer;
}

/* Hide the scrollbar */
body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}
