.parentContainer{
    background-color: #E4E4E4;
    opacity: 0.97;
    display: grid;
    /* grid-template-columns: 60vw 15vw; */
  
    /* Divide into template of 10 by 10 where each cell is same size*/
    grid-template-columns:repeat(10,minmax(0,1fr));
    grid-template-rows:repeat(10,minmax(0,1fr));
    /* Allow components/elements to fill empty cells */
    grid-auto-flow: dense;
    height:100vh;
    width:100wh;
  }

  .currentDate {
    grid-column-start:10;
    grid-row-start:1;
  }

  .navBar {
    grid-column-start:1;
    grid-row-start:1;
  }

  .line{
    display: flex;
    height: 2px;
    background-color: black;
    position: relative;
    /* Adjust the left and width properties to get line to start just after beginning and just before end! */
    /* In this case starting 2% after beginning and 2% before end (left+width=98%) */
    top:50%;
    left: 2%;
    width: 96%; 
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: 10;
    /* Used to disribute space between flex elements */
    /* Used to have text appear at start and end of line! */
    justify-content: space-between;
  }
