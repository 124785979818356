.filterBoxContainer * {
    box-sizing: border-box;
  }
.filterBoxContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    border: 1px solid black;
    width:90%;
    left: 50%;
    transform: translateX(-50%);
    top:5%;
    padding: 2% 3% 2% 3%;
    gap: 1% 4%;
    white-space: nowrap;
}
.filterBoxTitle {
    grid-column:span 5;
    align-self:start;
    cursor: pointer;
}
.filterBoxCloseButton
{
    position: absolute;
    top:0%;
    right:0%;
    border:1px solid black;
    cursor: pointer;
}
.filterBoxCloseButton:hover{
    background-color: black;
    color: white;
}
.filterInputContainer {
    grid-column: span 2;
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
}

.filterElement{
    width:100%;
}

.smallFilterInputContainer {
    grid-column: span 1;
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
}
.smallFilterElement {
    width:100%;
}

.setFilterButton
{
    grid-column: span 4;
    height:80%;
    width:20%;
    align-self:end;
    justify-self:end;
}

.modalClass {
    z-index: 15;
}