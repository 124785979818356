.support-section {
    display: static;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0rem 1rem;
    background-color: #192234;
    text-align: center;
    font-family: 'Jost', sans-serif;
  }
  
  .support-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .support-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 1rem;
  }
  
  .support-categories {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .category-button {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #192234; /* Blue button */
    border: 2px solid #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease;

  }
  
  .category-button:hover {
    background-color: #192234;
    color: #ffffff;
    border: 2px solid #1e3a8a;
  }
  
  .category-button.active {
    background-color: #34793a;
    color: #fff;
  }
  
  .support-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    max-width: 100%;
    margin: 2rem auto;
    padding: 0 1rem;
  }
  
  .support-item {
    position: relative;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    width: 80%;
    background-color: #f8f4f4;
    border: 1px solid #ddd;
  }
  
  .support-item:hover {
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .support-item.open {
    background-color: #eaeaea;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .support-question {
    font-size: 1.25rem;
    color: #111;
    font-weight: bold;
  }
  
  .support-answer {
    overflow: hidden;
    max-height: 0;
    display: block;
    margin-top: 0;
    padding: 0;
    font-size: 1rem;
    color: #000;
    line-height: 1.5;
    transition: max-height 0.3s ease, padding 0.3s ease;
  }
  
  .support-item.open .support-answer {
    max-height: 300px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  /* Support Section Footer */
.support-footer {
    margin-top: 2rem;
    text-align: center;
    font-size: 1.2rem;
    color: #ffffff;
  }
  
  .support-footer .contact-link {
    color: #8979FF; /* Link color */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease, text-decoration 0.3s ease;
  }
  
  .support-footer .contact-link:hover {
    color: #8979FF; /* Darker blue on hover */
    text-decoration: underline;
  }
  

  
/* Mobile Styles */
@media (max-width: 768px) {
  .support-title {
    font-size: 2rem; /* Adjust title size */
    margin-bottom: 1.5rem;
  }

  .support-categories {
    display: flex; /* Flexbox for horizontal alignment */
    flex-wrap: wrap; /* Allow buttons to wrap to the next row if needed */
    justify-content: center;
    gap: 0.5rem;
  }

  .category-button {
    width: auto; /* Allow buttons to scale dynamically */
    text-align: center;
  }

  .support-grid {
    padding: 0 1rem; /* Add padding for better spacing */
  }

  .support-item {
    width: 100%; /* Ensure items fit the screen */
    max-width: 90%; /* Limit maximum width */
    margin: 0 auto; /* Center items */
  }

  .support-footer {
    font-size: 1rem;
    padding: 1rem;
  }
}
