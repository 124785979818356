.geoSelectionContainer{
    padding-top:5%;
    padding-left:5%;
    position: relative;
    height:100%;
    width:100%;
}
.geoSelectionContainer > p {
    margin: 0;
}

.resetFilterButton{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: underline;
    bottom: 10%; /* Adjust as needed */
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
}

.categoryFilter{
    position: absolute;
    text-align: center;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid;
    border-radius: 10px;
    padding:5px;
    bottom: 20%; /* Adjust as needed */
    cursor:pointer;
}
.categoryFilter:hover{
    background-color: lightgray;
}
