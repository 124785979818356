.categoryContainer {
    display: grid;
    place-items: center;
}
.categoryTable {
    margin-bottom:5vh;
}
.exitButton {
    position: absolute;
    top:0%;
    right:0%;
}
.modalClass {
    z-index: 15;
}