.dashboardParent {
    display: grid;
    /* Divide into template of 20 by 20 where each cell is same size*/
    grid-template-columns:repeat(20,minmax(0,1fr));
    grid-template-rows:repeat(20,minmax(0,1fr));
    /* Allow components/elements to fill empty cells */
    grid-auto-flow: dense;
    height:100vh;
    width:100wh;
    padding:1%;
    gap:0.5%;
}
/* Class defined for every element to give default border */
.defaultBorder {
    padding:1%;
    background-color: rgb(253, 253, 253);
    /* Enable scrolling when content exceeds height! */
    overflow: auto;
    /* Commented out resize - problem with z-indexing (resizing being blocked by other divs!) */
    /* resize:both; */
    z-index:1;
    border-radius: 4px;
    background-color: #333333;
}

.industryBreakdown{
    grid-column-start:1;
    grid-column-end:8;
    grid-row-start:4;
    grid-row-end:16;
}
/* The chart is in a div (make it so it returns to full width/height after being resized) */
.industryBreakdown > div {
    width:100%;
    height:100%;
}
.indusutryBreakdown > div > canvas {
    height: 50%;
}

.activeFilters {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start:1;
    grid-row-end: 4;
}

.upButton {
    grid-column-start:1;
    grid-row-start:1;
    grid-row-end: 4;
}

.scannedArticles
{
    text-align:center;
    grid-column-start:5;
    grid-column-end:8;
    grid-row-start:1;
    grid-row-end:4;
}
/* Get rid of magin for all child elements (> * being one level deep)  */
.scannedArticles > * {
    margin: 0;
} 

.sentimentClass
{
    text-align:center;
    grid-column-start:8;
    grid-column-end:17;
    grid-row-start:1;
    grid-row-end: 10;
    display: flex;
    flex-direction: column; /* Display items top to bottom */
    align-items: center; /* Center horizontally within the grid item */
    justify-content: center; /* Center vertically within the grid item */
}
/* The chart is in a div (make it so it returns to full width/height after being resized) */
.sentimentClass > div {
    width:100%;
    height:100%;
}
.sentimentClass > div > canvas {
    width: 100%;
}
.publisherClass
{
    text-align:center;
    grid-column-start:8;
    grid-column-end:17;
    grid-row-start:10;
    grid-row-end: 20;
    display: flex;
    flex-direction: column; /* Display items top to bottom */
    align-items: center; /* Center horizontally within the grid item */
    justify-content: center; /* Center vertically within the grid item */
}
/* The chart is in a div (make it so it returns to full width/height after being resized) */
.publisherClass > div {
    width:100%;
    height:100%;
}

.keywords {
    grid-column-start:1;
    grid-column-end:8;
    grid-row-start:16;
    grid-row-end:20;
}

.relatedSectorIndustry {
    grid-column-start: 17;
    grid-column-end:21;
    grid-row-start:1;
    grid-row-end:5;
}

.trendingArticles {
    position:relative;
    grid-column-start: 17;
    grid-column-end:21;
    grid-row-start:5;
    grid-row-end:20;
    padding:2;
    border-radius: 4px;
}

.next-section {
    min-height: 100vh; /* Ensure each section takes at least the full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4; /* Change color for section two */
    color: #333;
  }
  
  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .image-container {
    flex: 1;
    margin-right: 20px; /* Adjust spacing */
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
  }
  
  .text-container {
    flex: 1;
  }
/* .defaultBorder:active {
    z-index:2;
} */