.loading-screen {
    grid-column-start:5;
    grid-column-end: 6;
    grid-row-start:4;
    grid-row-end: 5;
    align-self: center; /* Align vertically */
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 5px; /* Gap between squares */
    max-width: 100%; /* Set a maximum width as a percentage of the screen width */
    aspect-ratio: 1/1; /* Maintain a 1:1 aspect ratio between height and width to make it a square */
    padding:2%;
    background-color:black;
  }
  
  .square {
    width:100%;
    height:100%;
    background-color: darkgray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .square.highlighted {
    background-color: white; /* Highlighted square color */
    color: white;
  }