  
  /* Style the profile menu */
  .profile-menu {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
    display: grid;
    grid-template-rows: repeat(4, 1fr); /* Adjust the number of rows as needed */
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 0.5px solid black;
    margin-left: 5%;

  }
  /* Title of the profile (selected option) */
  .profileTitle {
    display: flex;
    grid-column-start:3;
    grid-column-end:8;
    grid-row-start:1;
    margin-left:3%;
    margin-bottom: 0;
    align-items: flex-end; /*align items at bottom of cell (instead of top)*/
  }
  .capitalize::first-letter {
    text-transform:capitalize;
  }
  /* Style the menu items */
  .menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid lightgray;
  }
  
  /* Style the active menu item */
  .menu-item.active {
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
  }
  
  /* Style the profile main content */
  .profile-main {
    position: relative;
    border: 1px solid #ccc;
    padding-top:2%;
    border-radius: 5px;
    grid-column-start: 3;
    grid-column-end: 11;
    grid-row-start: 2;
    grid-row-end: 10;
    margin-left:2%;
    margin-right:2%;
    overflow: auto;
  }
  
  .accountInfoContainer {
    display: grid;
    align-items: center;
    grid-template-rows: auto 1fr;
  }
/* Spacing between elements */
  .accountInfoContainer > label{
    margin-top:3%;
  }
/* Override the first element's spacing */
  .accountInfoContainer > label:first-child {
    margin-top:0%;
  }

  .accountSubmitButton {
    margin-top:3%;
    width:50%;
    align-self: center; /*center vertically*/
    justify-self: center; /*center horizontally*/
  }
  
  .accountDeleteButton {
    width:50%;
    margin-top:30%;
    align-self: center; /*center vertically*/
    justify-self: center; /*center horizontally*/
  }

  .deleteAccountConfirmation{
    display: grid;
    grid-gap:20%;

  }
  
  .deleteAccountConfirmation > div {
    width: 80%;
    justify-self: center;
  }

.deleteAccountAfterConfirmationButton > button {
    background-color: red;
}

.resetPasswordContainer {
  display: grid;
  align-items: center;
  grid-template-rows: auto 1fr;
  padding-left: 2%;
  padding-right:2%;
}
/*MUI Input in Divs, so referencing spacing between buttons using the div*/
.resetPasswordContainer > div {
  margin-bottom:5%;
}

.activityContainer { 
  display: grid;
  height:100%;
  width:100%;
  /* Divide into template of 10 by 10 where each cell is same size*/
  grid-template-columns:repeat(10,10%);
  grid-template-rows:repeat(10,10%);
}

.activityViewedArticles{
  /*Span first row */
  grid-row: span 1;
  grid-column-start: 1;
  grid-column-end: 3;
}

.activitySectorGraph {
  grid-row-start: 1;
  grid-row-end: 5;
  grid-column-start: 4;
  grid-column-end: 8;
  text-align: center;
}

.activityContinentGraph {
  grid-row-start:6;
  grid-row-end: 10;
  grid-column-start: 2;
  grid-column-end: 6;
  text-align: center;
}

.activityCountryGraph {
  grid-row-start:6;
  grid-row-end: 10;
  grid-column-start: 7;
  grid-column-end: 11;
  text-align: center;
}
