/* Container Styling */
.terms-container {
    display: flex;
    flex-direction: column;
    background-color: #192234; /* Background color */
    width: 100%;
    margin-top: 2rem;
}

/* Header Styling */
html {
    scroll-behavior: smooth;
}

/* Terms Sections */
.terms-sections {
    margin: 0 auto; /* Center the content */
    width: 100%;
    max-width: 1200px;
}

.terms-sections h2 {
    margin-top: 1rem;
    font-size: 2rem;
    color: rgb(255, 255, 255);
}

.terms-sections h3 {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: rgb(255, 255, 255);
}

.terms-sections h4 {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: rgb(255, 255, 255);
}

.terms-sections p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: white;
}

/* General Styling for Lists */
ul {
    list-style-type: disc; /* Default bullet style */
    margin-left: 1.5rem; /* Add some indentation */
    padding-left: 1rem; /* Space between bullet and text */
  }
  
  ul li {
    font-size: 1rem; /* Adjust font size for readability */
    line-height: 1.6; /* Space between list items */
    color: #ffffff; /* Text color to match the rest of the content */
    margin-bottom: 0.5rem; /* Space between each list item */
    transition: color 0.3s ease; /* Smooth transition for hover effects */
  }
  
  ul li:hover {
    color: orange; /* Highlight color when hovering over list items */
  }
  
  /* Nested Lists */
  ul ul {
    list-style-type: circle; /* Change bullet style for nested lists */
    margin-left: 2rem; /* Further indentation for nested lists */
  }
  
  ul ul li {
    font-size: 0.9rem; /* Slightly smaller font size for nested items */
    color: #cccccc; /* Softer color for nested items */
  }
  
  /* Numbered Lists */
  ol {
    list-style-type: decimal; /* Default number style */
    margin-left: 1.5rem; /* Indentation for ordered lists */
    padding-left: 1rem; /* Space between number and text */
  }
  
  ol li {
    font-size: 1rem; /* Adjust font size */
    line-height: 1.6; /* Space between items */
    color: #ffffff; /* Text color */
    margin-bottom: 0.5rem; /* Space between list items */
  }
  
  /* Hover Effect for Numbered Lists */
  ol li:hover {
    color: rgb(255, 255, 255); /* Highlight number and text on hover */
  }
  
  /* Accessibility Enhancements */
  ul, ol {
    margin-top: 1rem; /* Add spacing above lists */
    margin-bottom: 1rem; /* Add spacing below lists */
  }
  
  ul li::marker,
  ol li::marker {
    color: rgb(255, 255, 255); /* Custom color for bullets or numbers */
  }
  
  /* Mobile-Specific Styles */
@media (max-width: 390px) {
    .terms-container {
        padding: 0.5rem; /* Reduce padding on mobile */
    }

    .terms-sections {
        max-width: 100%; /* Ensure the section takes full width */
        padding: 0.5rem; /* Reduce padding for smaller screens */
    }

    .terms-sections h2 {
        font-size: 1.5rem; /* Smaller header font for mobile */
        text-align: left; /* Center-align headers on mobile */
        max-width: 80%;
    }

    .terms-sections h3,
    .terms-sections h4 {
        font-size: 1.2rem; /* Adjust header font size */
        text-align: left; /* Center-align headers */
    }

    .terms-sections p {
        font-size: 0.9rem; /* Reduce paragraph font size */
        line-height: 1.4; /* Adjust line height for readability */
        text-align: left; /* Keep text justified for neatness */
        max-width: 90%;
    }

    ul, ol {
        margin-left: 1rem; /* Keep lists indented properly */
        padding-left: 0.5rem; /* Reduce padding for bullets/numbers */
    }

    ul li, ol li {
        font-size: 0.8rem; /* Smaller font for list items */
    }
}