/* Container Styling */
.privacy-policy-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Header Styling */
.header {
  background-color: #33333300;

}

.logo {
  color: white;
  font-size: 1.6rem;
  text-decoration: none;
  font-weight: bold;
}

/* Main Content Layout */
.content-container {
  display: flex;
  flex-grow: 1;
  padding: 2rem;
}

/* Table of Contents */
.table-of-contents {
  width: 20%;
  position: fixed;
  top: 100px; /* Positioned below the header */
  left: 2rem;
}

.table-of-contents h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: white;
}

.table-of-contents ul {
  list-style: none;
  padding: 0;
}

.table-of-contents li {
  margin-bottom: 1rem;
}

.table-of-contents li.active a {
  font-weight: bold;
  color: orange;
}

.table-of-contents li a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.table-of-contents li a:hover {
  color: orange;
}

/* Privacy Sections */
.policy-content {
  margin-left: 25%;
  width: 75%;
}

.policy-content h2 {
  margin-top: 3rem;
  font-size: 2rem;
  color: orange;
}

.policy-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: white;
}

/* Scrollbar Styling (Optional, for a polished look) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #333;
}

::-webkit-scrollbar-thumb {
  background-color: orange;
  border-radius: 10px;
}
