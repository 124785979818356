.categoryCell {
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s;
  }
  
  .categoryCell:hover {
    background-color: #f5f5f5;
  }
  
.categoryButton {
    padding: 0;
    border: none;
    /* background-color: transparent; */
    cursor: pointer;
    color: #080808;
    transition: color 0.3s;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .categoryButton:hover {
    color: orange;
  }

  .tableStyle
  {
    border: 1px solid black;
    width: 60vw;
    height: 30vh;
  }
  .tableStyle td {
    border: 1px solid black;
    width: 20%;
    height:33.3%;
  }

  .categoryTableContainer {
    border: 1px solid #000;
    border-radius: 10px;
    padding: 10px;
  }

  .selectedCell{
    background-color: gray;
  }
