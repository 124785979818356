/* Stat Box Styling */
.stat-box {
    background-color: #192234;
    color: #ffffff;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .stat-number {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .stat-label {
    font-size: 1.25rem;
    color: #cbd5e1;
    text-align: center;
  }
  .animated-stat-box-container {
  width: 100px; /* Set a fixed width */
  height: 50px; /* Set a fixed height */
  overflow: hidden; /* Prevent content overflow */
  text-align: center; /* Optional: Align the content */
}

.stat-number {
  font-size: 2rem;
  font-weight: bold;
  white-space: nowrap; /* Prevent the number from wrapping */
}
