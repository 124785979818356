.footer {
    color: white;
    position: fixed;
    bottom: 20px;
    left: 10px;
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 400ms, border-color 400ms, width 400ms ease-in-out;
    z-index: 10000;
    width: 120px; /* Initial width */
    overflow: hidden; /* Hide content overflow */
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .footer:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .footer.expanded {
    width: 435px; /* Expanded width */
  }
  
  .subcategories {
    display: flex;
    flex-direction: row;
    gap: 5px;
    opacity: 0; /* Hidden initially */
    transition: opacity 400ms ease-in-out;
    margin-left: 10px; /* Space between footer content and subcategories */
  }
  
  .footer.expanded .subcategories {
    opacity: 1; /* Visible when expanded */
  }
  